/* eslint-disable prettier/prettier */
import { useAuth } from "app/auth/context";
import Button from "app/core/components/Button";
import Checkbox from "app/core/components/Checkbox";
import Input from "app/core/components/Input";
import Select from "app/core/components/Select";
import { Block } from "baseui/block";
import { CollageDesignerContext } from "contexts/CollageDesignerContext";
import { ReactElement, useContext, useState } from "react";

import BackgroundMusicModal from "./BackgroundMusicModal";
import { ANIMATION_OPTIONS } from "./constants";
import PublishModal from "./PublishModal";
import { CollageDesignerActionType } from "./types";
import ColorPicker from "./ui/ColorPicker";
import FormControl from "./ui/FormControl";

export default function CollageDetailsPanel(): ReactElement {
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isBackgroundMusicModalOpen, setIsBackgroundMusicModalOpen] = useState(
    false
  );
  const {
    state: {
      collageBody: {
        nodeEntryAnimation,
        isPhotoPreviewEnabled,
        photoPreviewAutoplaySpeed,
        isPinterestEnabled,
        gap,
        backgroundColor,
      },
      collageId,
    },
    dispatch,
  } = useContext(CollageDesignerContext);
  const { verifyFeatureAvailability } = useAuth();

  return (
    <>
      <Block marginBottom="20px" width="100%">
        <Button
          onClick={() => setIsBackgroundMusicModalOpen(true)}
          $style={{ width: "100%" }}
        >
          Add music
        </Button>
      </Block>
      {/* <FormControl
          label={() => "Nazwa"}
          error={errors.name && errors.name.message}
        >
          <ControlledInput
            control={control}
            name="name"
            error={!!errors.name}
            rules={{ required: "This field is required" }}
            disabled={isSubmitting}
          />
        </FormControl> */}
      <FormControl label={() => "Animation"}>
        <Select
          clearable
          options={ANIMATION_OPTIONS}
          value={nodeEntryAnimation ? [{ id: nodeEntryAnimation }] : undefined}
          onChange={(params) =>
            params && params.value && params.value.length > 0
              ? dispatch({
                  type: CollageDesignerActionType.ChangeCollageBody,
                  payload: {
                    nodeEntryAnimation: params.value[0].id as string,
                  },
                })
              : dispatch({
                  type: CollageDesignerActionType.ChangeCollageBody,
                  payload: {
                    nodeEntryAnimation: null,
                  },
                })
          }
        />
      </FormControl>
      <FormControl>
        <Checkbox
          checked={!!isPhotoPreviewEnabled}
          onChange={(event: React.FormEvent<HTMLInputElement>) =>
            dispatch({
              type: CollageDesignerActionType.ChangeCollageBody,
              payload: {
                isPhotoPreviewEnabled: event.currentTarget.checked,
              },
            })
          }
        >
          Lightbox (photo preview)
        </Checkbox>
      </FormControl>
      {isPhotoPreviewEnabled && (
        <FormControl label="Autoplay speed">
          <Input
            value={(photoPreviewAutoplaySpeed ?? 5).toString()}
            onChange={(event: React.FormEvent<HTMLInputElement>) =>
              dispatch({
                type: CollageDesignerActionType.ChangeCollageBody,
                payload: {
                  photoPreviewAutoplaySpeed: Math.max(
                    Math.min(parseFloat(event.currentTarget.value), 100),
                    0.5
                  ),
                },
              })
            }
            endEnhancer="sec."
            type="number"
            step={0.5}
            min={0.5}
            max={100}
            placeholder="5"
          />
        </FormControl>
      )}
      <FormControl>
        <Checkbox
          checked={!!isPinterestEnabled}
          disabled={!verifyFeatureAvailability("pinterest")}
          onChange={(event: React.FormEvent<HTMLInputElement>) =>
            dispatch({
              type: CollageDesignerActionType.ChangeCollageBody,
              payload: {
                isPinterestEnabled: event.currentTarget.checked,
              },
            })
          }
        >
          Pinterest
        </Checkbox>
      </FormControl>
      <FormControl label="Gap between images">
        <Input
          value={gap?.toString() ?? "0"}
          onChange={(event: React.FormEvent<HTMLInputElement>) =>
            dispatch({
              type: CollageDesignerActionType.ChangeCollageBody,
              payload: {
                gap: Math.max(
                  Math.min(parseInt(event.currentTarget.value), 30),
                  0
                ),
              },
            })
          }
          type="number"
          min={0}
          max={30}
        />
      </FormControl>
      <FormControl label="Background color">
        <ColorPicker
          initialColor={backgroundColor}
          onPick={(color) =>
            dispatch({
              type: CollageDesignerActionType.ChangeCollageBody,
              payload: {
                backgroundColor: color,
              },
            })
          }
        />
      </FormControl>
      {/* <Button
        size="mini"
        onClick={() => dispatch({ type: UndoableActionType.Undo })}
        $style={{ marginTop: "20px" }}
        disabled={!canUndo}
      >
        Undo
      </Button>
      <Button
        size="mini"
        onClick={() => dispatch({ type: UndoableActionType.Redo })}
        $style={{ marginTop: "20px" }}
        disabled={!canRedo}
      >
        Redo
      </Button> */}
      {collageId && (
        <>
          {/* <Button
            size="mini"
            onClick={() => setIsExportModalOpen(true)}
            $style={{ marginTop: "20px" }}
          >
            Eksportuj
          </Button> */}
          <PublishModal
            isOpen={isExportModalOpen}
            onClose={() => setIsExportModalOpen(false)}
          />
          {isBackgroundMusicModalOpen && (
            <BackgroundMusicModal
              isOpen={isBackgroundMusicModalOpen}
              onClose={() => setIsBackgroundMusicModalOpen(false)}
            />
          )}
        </>
      )}
    </>
  );
}
